<template>
  <div class="border-b border-gray-300">
    <div class="flex items-center justify-between px-4 py-3">
      <oto-typography :text="textLeft" variant="three" class="text-sm" />
      <oto-typography :text="textRight" variant="two" class="text-xl" />
    </div>
  </div>
</template>

<script>
import OtoTypography from '@/components/ui/OtoTypography'

export default {
  name: 'FeatureListTypography',
  components: {
    OtoTypography,
  },
  props: {
    textLeft: {
      type: [String, Number],
      required: false,
    },
    textRight: {
      type: [String, Number],
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
